import { post, get, put, del } from '_helpers/ApiService'

export default{
    state: () => ({
        codes: [],
        employee_vouchers: []
    }),
    getters: {
        CODES(state) {
            return state.codes
        },
        EMPLOYEE_VOUCHERS(state) {
            return state.employee_vouchers
        }
    },
    mutations: {
        SET_CODES(state, payload) {
            state.codes = payload
        },
        SET_EMPLOYEE_VOUCHERS(state, payload) {
            state.employee_vouchers = payload
        }
    },
    actions: {
        async FETCH_CODES({ commit, state}, payload) {
            try {
                const { data } = await get(`employee-code/all`, payload)
                
                await commit('SET_CODES', data.codes)

                return data
            } catch(error) {
                console.log('error', error)
            }
        },
        async GENERATE_CODES({ commit, state }, payload) {
            try {
                const { data } = await post('employee-code/generate', payload)

                return data
            } catch(error) {
                console.log('error', error)
            }
        },
        async TOGGLE_CODE_STATUS({ commit, state }, payload) {
            try {
                const { data } = await put(`employee-code/status/${payload.id}`, payload)

                return data
            } catch(error) {
                console.log('error', error)
            }
        },
        async FETCH_EMPLOYEE_VOUCHERS({ commit, state }, payload) {
            try {
                const { data } = await get('employee-voucher/all', payload)

                return data
            } catch(error) {
                console.log('error', error)
            }
        },
        async UPDATE_EMPLOYEE_VOUCHER_VALUE({ commit, state }, payload) {
            try {
                const { data } = await put(`employee-voucher/value/${payload.id}`, payload)

                return data
            } catch(error) {
                console.log('error', error)
            }
        },
        async FETCH_EMPLOYEE_WALLET({ commit, state }, payload) {
            try {
                const { data } = await get(`employee-voucher/wallet`)

                return data
            } catch(error) {
                console.log('error', error)
            }
        },
        async FETCH_PARTNERS({ commit, state }, payload) {
            try {
                const { data } = await get(`partners/all`, payload)

                return data
            } catch(error) {
                console.log('error', error)
            }
        },
        async TOGGLE_PARTNER_STATUS({ commit, state }, payload) {
            try {
                const { data } = await put(`partners/status/${payload.id}`, payload)

                return data
            } catch(error) {
                console.log('error', error)
            }
        },
        async DOWNLOAD_CODE_PDF({ commit, state }, payload) {
            try {
                const { data } = await post('employee-code/download', payload, {}, {responseType: 'arraybuffer'})
                let blob = new Blob([data], { type: 'application/pdf' })
                let link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = `${payload.code}.pdf`
                link.click()

                return data
            } catch(error) {
                console.log('error', error)
            }
        },
    }
}