import Vue from 'vue';
import Vuex from 'vuex';

import Auth from './Modules/Auth/'
import Voucher from './Modules/Voucher/'
import ScannerUser from './Modules/ScannerUser/'
import Cart from './Modules/Cart/'
import Seller from './Modules/Seller/'
import Wallet from './Modules/Wallet/'
import User from './Modules/User/'
import Category from './Modules/Category/'
import SubCategory from './Modules/SubCategory/'
import Template from './Modules/Template/'
import Region from './Modules/Region/'
import Alert from './Modules/Alert/'
import UserStripe from './Modules/UserStripe/'
import UserVoucher from './Modules/UserVoucher/'
import UserVoucherWishlist from './Modules/UserVoucherWishlist/'
import QrCode from './Modules/QrCode/'
import Redemption from './Modules/Redemption/'
import GlobalSetting from './Modules/GlobalSetting/'
import UserSetting from './Modules/UserSetting/'
import SellerInvoice from './Modules/SellerInvoice/'
import TransferUrl from './Modules/TransferUrl/'
import Tourism from './Modules/Tourism/'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Auth,
    Voucher,
    ScannerUser,
    Cart,
    Seller,
    Wallet,
    User,
    Category,
    SubCategory,
    Template,
    Region,
    Alert,
    UserStripe,
    UserVoucher,
    UserVoucherWishlist,
    QrCode,
    Redemption,
    GlobalSetting,
    UserSetting,
    SellerInvoice,
    TransferUrl,
    Tourism
  }
})