import { post, get, put, del, patch } from '_helpers/ApiService'
import { toFormData } from '_helpers/CustomFunction'
import moment from 'moment'

const prefix = 'user'

export default {
  state: () => ({
    users: [],
    company: null,
    user: null
  }),
  getters: {
    USER(state) {
      return state.user;
    },
    COMPANY(state) {
      return state.company;
    },
    USERS(state) {
      return state.users;
    },
  },
  mutations: {
    SET_USER(state, payload) {
      state.user = payload
    },
    SET_COMPANY(state, payload) {
      state.company = payload
    },
    SET_USERS(state, payload) {
      state.users = payload
    },
  },
  actions: {
    async FETCH_USER_FILTER( { commit, state }, payload )
    {
      try {
        const { data } = await get(`${prefix}/user-filter`, payload)
        await commit('SET_USERS', data.users)
        return data
      } catch (err) {
        throw err
      }
    },
    async FETCH_USER( { commit, state }, payload )
    {
      try {
        const { data } = await get(`${prefix}/${payload.id}`, payload)
        await commit('SET_USER', data.user)
        return data
      } catch (err) {
        throw err
      }
    },
    async FETCH_USER_BY_NAME( { commit, state }, payload )
    {
      try {
        console.log(payload)
        const { data } = await get(`${prefix}/company/name`, payload)
        await commit('SET_COMPANY', data.user)
        return data
      } catch (err) {
        throw err
      }
    },
    async FETCH_USER_STIPE_STATUS()
    {
      try {
        const { data } = await get(`${prefix}/stipe-status`)
        return data
      } catch (err) {
        throw false
      }
    },
    async ADD_USER( { commit, state }, payload )
    {
      try {
        // if( payload.company && typeof payload.company.logo == 'string' ) {
        //   payload.company.logo = ''
        // }
        const formData = toFormData(payload)
        const { data } = await post(`auth/register`, formData)
        await commit('SET_USER', data.user)
        return data
      } catch (err) {
        throw err
      }
    },
    async UPDATE_USER( { commit, state }, payload )
    {
      try {
        // if( payload.company && typeof payload.company.logo == 'string' ) {
        //   payload.company.logo = ''
        // }
        const formData = toFormData(payload)
        const { data } = await post(`${prefix}/${payload.id}`, formData)
        let auth = await JSON.parse(localStorage.getItem('_auth'))
        auth = {
          ...auth,
          data: data.user
        }
        await commit('SET_AUTH_USER', auth)
        await localStorage.setItem('_auth', JSON.stringify(auth))
        await commit('SET_USER', data.user)
        return data
      } catch (err) {
        throw err
      }
    },
    async DELETE_USER( { commit, state }, payload )
    {
      try {
        const { data } = await del(`${prefix}/${payload.id}`, {})
        const newData = state.users.filter( row => row.id != payload.id);
        await commit('SET_USERS', newData)
      } catch (err) {
        throw err
      }
    },
    async CHANGE_PASSWORD( { commit, state }, payload )
    {
      try {
        const { data } = await patch(`${prefix}/change-password/${payload.id}`, payload)
        return data
      } catch (err) {
        throw err
      }
    },
    async UPDATE_USER_STATUS( { commit, state }, payload )
    {
      try {
        const { data } = await put(`${prefix}/user-status/${payload.id}`, payload)
        const newData = state.users.map( row => {
          if( row.id == payload.id ) {
            row.isActivated = !row.isActivated
          }
          return row
        })
        await commit('SET_USERS', newData)
        return data
      } catch (err) {
        throw err
      }
    },
    async TOGGLE_USER_GIFT_STATUS( { commit, state }, payload )
    {
      try {
        const { data } = await put(`${prefix}/user-gift-status/${payload.id}`, payload)
        const newData = state.users.map( row => {
          if( row.id == payload.id ) {
            row.isActivated = !row.isActivated
          }
          return row
        })
        await commit('SET_USERS', newData)
        return data
      } catch (err) {
        throw err
      }
    },
    async TOGGLE_USER_AUTH_STATUS( { commit, state }, payload )
    {
      try {
        const { data } = await put(`${prefix}/user-auth-status/${payload.id}`, payload)
        const newData = state.users.map( row => {
          if( row.id == payload.id ) {
            row.isActivated = !row.isActivated
          }
          return row
        })
        await commit('SET_USERS', newData)
        return data
      } catch (err) {
        throw err
      }
    },
    async TOGGLE_USER_REPORTING_STATUS( { commit, state }, payload )
    {
      try {
        const { data } = await put(`${prefix}/user-monthly-reporting-status/${payload.id}`, payload)
        const newData = state.users.map( row => {
          if( row.id == payload.id ) {
            row.isActivated = !row.isActivated
          }
          return row
        })
        await commit('SET_USERS', newData)
        return data
      } catch (err) {
        throw err
      }
    },
    async TOGGLE_USER_CUSTOM_ORDER_CONFIRMATION( { commit, state }, payload )
    {
      try {
        const { data } = await put(`${prefix}/user-custom-order-confirmation/${payload.id}`, payload)
        const newData = state.users.map( row => {
          if( row.id == payload.id ) {
            row.isActivated = !row.isActivated
          }
          return row
        })
        await commit('SET_USERS', newData)
        return data
      } catch (err) {
        throw err
      }
    },
  },
}